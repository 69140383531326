<template>
  <div class="bills-container">
    <!-- 修改账单 -->
    <CSDialog
      :dialogTitle="disposeResTitle"
      :dialogVisible="orderContentVisible"
      dialogWidth="812px"
      @onClose="orderContentVisible = false"
      @onConfirm="reopenWorkOrder"
    >
      <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px">
        <div style="margin-bottom: 23px">
          <label style="width: 200px; margin-right: 50px; text-align: right"
            >物业计费期限</label
          >
          <span>
            {{ editCompanyOwnerInfo.startTime }}至{{
              editCompanyOwnerInfo.endTime
            }}
          </span>
        </div>
        <div style="margin-bottom: 23px">
          <label style="width: 200px; margin-right: 50px; text-align: right"
            >计费标准</label
          >
          <span> {{ editCompanyOwnerInfo.ruleCharging }}元/m²/月 </span>
        </div>
        <div style="margin-bottom: 23px">
          <label style="width: 200px; margin-right: 50px; text-align: right"
            >应缴费用</label
          >
          <span> {{ editCompanyOwnerInfo.feePayable }}元 </span>
        </div>
        <div style="margin-bottom: 23px">
          <label style="width: 200px; margin-right: 50px; text-align: right"
            >附加费用</label
          >
          <input
            type="number"
            placeholder="0-999999"
            maxlength="10"
            style="width: 400px; border: 1px solid #999; border-radius: 4px;padding-left:10px"
            v-model="editCompanyOwnerInfo.additional"
            @input="itionalBtn"
          />
        </div>
        <div style="margin-bottom: 23px">
          <label style="width: 200px; margin-right: 50px; text-align: right"
            >减免金额</label
          >
          <input
            type="number"
            placeholder="0-999999"
            maxlength="10"
            style="width: 400px; border: 1px solid #999; border-radius: 4px;padding-left:10px"
            v-model="editCompanyOwnerInfo.feeWaiver"
            @input="itionalBtn"
          />
        </div>
        <div style="margin-bottom: 23px">
          <label style="width: 200px; margin-right: 50px; text-align: right"
            >实缴费用</label
          >
          <span>{{ editCompanyOwnerInfo.actualPayable }}</span>
        </div>
        <div style="margin-bottom: 23px">
          <label style="width: 200px; margin-right: 50px; text-align: right"
            >备注</label
          >
          <textarea
            class="el-input"
            placeholder="请输入"
            v-model="editCompanyOwnerInfo.textarea"
            maxlength="100"
          >
          </textarea>
        </div>
      </div>
    </CSDialog>
  </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";
import { editBillPropertyUrl } from "@/requestUrl";
export default {
  data() {
    return {
      disposeResTitle: "修改账单",
      orderContentVisible: false,
      editCompanyOwnerInfo: {
        additional: "", //附加费用
        feeWaiver: "", //减免费用
        textarea: "", //备注tianxie
        id: "",
        startTime: "",
        endTime: "",
        ruleCharging: "", //计费标准
        feePayable: "", //应缴费用
        actualPayable: "", //实缴费用
      },
      accuracy: 1000,
    };
  },
  components: {
    CSDialog,
  },
  mounted() {
    this.$vc.on(this.$route.path, "ModifyBillS", "showOn", (item) => {
      this.backfillBtn(item.id);
      this.orderContentVisible = true;
    });
  },
  methods: {
    //附加费用
    itionalBtn(e) {
       if(+this.editCompanyOwnerInfo.additional >= 999999){
        this.editCompanyOwnerInfo.additional = 999999
      }
      if(+this.editCompanyOwnerInfo.feeWaiver >= 999999){
        this.editCompanyOwnerInfo.feeWaiver = 999999
      }
      if (
        this.editCompanyOwnerInfo.additional &&
        !/^-?\d+\.?\d{0,2}$/.test(this.editCompanyOwnerInfo.additional)
      ) {
        this.editCompanyOwnerInfo.additional =
          this.editCompanyOwnerInfo.additional.substring(
            0,
            this.editCompanyOwnerInfo.additional.indexOf(".") + 3
          );
      }
      if (
        this.editCompanyOwnerInfo.feeWaiver &&
        !/^-?\d+\.?\d{0,2}$/.test(this.editCompanyOwnerInfo.feeWaiver)
      ) {
        this.editCompanyOwnerInfo.feeWaiver =
          this.editCompanyOwnerInfo.feeWaiver.substring(
            0,
            this.editCompanyOwnerInfo.feeWaiver.indexOf(".") + 3
          );
      }
      if (+this.editCompanyOwnerInfo.additional < 0) {
        this.editCompanyOwnerInfo.additional = "";
      }
      if (+this.editCompanyOwnerInfo.feeWaiver < 0) {
        this.editCompanyOwnerInfo.feeWaiver = "";
      }
      this.editCompanyOwnerInfo.actualPayable =
        (+this.editCompanyOwnerInfo.feePayable * this.accuracy +
          +this.editCompanyOwnerInfo.additional * this.accuracy -
          this.editCompanyOwnerInfo.feeWaiver * this.accuracy) /
        this.accuracy;
      if(this.editCompanyOwnerInfo.actualPayable < 0){
        this.editCompanyOwnerInfo.actualPayable  = 0;
      }
     
    },
    //回填
    backfillBtn(item) {
      this.editCompanyOwnerInfo.id = item.id;
      this.editCompanyOwnerInfo.additional = item.additionalPayable;
      this.editCompanyOwnerInfo.feeWaiver = item.creditAmount;
      this.editCompanyOwnerInfo.textarea = item.commit;
      this.editCompanyOwnerInfo.startTime = item.startDate;
      this.editCompanyOwnerInfo.endTime = item.endDate;
      this.editCompanyOwnerInfo.ruleCharging = item.ruleCharging;
      this.editCompanyOwnerInfo.feePayable = item.feePayable;
      this.editCompanyOwnerInfo.actualPayable = item.actualPayable;
    },
    reopenWorkOrder() {
      if(this.editCompanyOwnerInfo.feeWaiver == ""){
        this.editCompanyOwnerInfo.feeWaiver = 0;
      }
      if(this.editCompanyOwnerInfo.additional==""){
        this.editCompanyOwnerInfo.additional = 0;
      }
      this.$fly
        .post(editBillPropertyUrl, {
          billId: this.editCompanyOwnerInfo.id, //账单id
          additionalPayable: this.editCompanyOwnerInfo.additional, //附加费用
          creditAmount: this.editCompanyOwnerInfo.feeWaiver, //减免金额
          commit: this.editCompanyOwnerInfo.textarea, //备注
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          this.$vc.toast("修改账单成功");
          this.orderContentVisible = false;
          this.$vc.emit(this.$router.path, "modifyUpdate", {});
        });
    },
  },
};
</script>

<style scoped>
.el-input {
  width: 460px;
  height: 180px;
  background-color: red;
  vertical-align: top;
  background: #f0f0f0;
  border-radius: 5px;
  padding: 10px;
  font-size: 24px;
  font-weight: normal;
  resize: none;
}
</style>